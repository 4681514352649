import React, { useState, useContext, useEffect, useRef } from "react";
import { Container, Grid, Box, Avatar, Typography, Accordion, AccordionSummary, AccordionDetails, Divider, InputAdornment, TextField, Button, Alert, IconButton, Select, Paper } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import img_ilumen from '../../../assets/img/Icono-iLUMEN-1.png'
import ApartmentIcon from '@mui/icons-material/Apartment';
import BadgeIcon from '@mui/icons-material/Badge';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import AttachmentIcon from '@mui/icons-material/Attachment';
import PersonIcon from '@mui/icons-material/Person';
import PasswordIcon from '@mui/icons-material/Password';
import GroupsIcon from '@mui/icons-material/Groups';
import ShieldIcon from '@mui/icons-material/Shield';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useMediaQuery } from '@material-ui/core';
import { ThemeProvider } from "@mui/material/styles";
import { theme_formulario_ilumen } from './theme_formulario_ilumen';
import { useTheme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@mui/icons-material/Edit';
import { Label } from '@material-ui/icons';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import { ComunicacionInternaContext } from "../../../context/FormsContext";
import { DatosMiPerfilContext } from "../../../context/MiPerfilContext";
import { AuthContext } from "../../../context/AuthContext";
import { capitalize } from "../../Utils/Utils";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import InputBase from '@mui/material/InputBase';
import { DataGrid } from "@mui/x-data-grid";
import ReactGA from 'react-ga4';

const url = process.env.REACT_APP_URL_ILUMEN;


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableCell: {
    borderBottom: 'none', // Elimina los bordes inferiores predeterminados
    borderBottomWidth: '1px', // Establece el grosor de la línea horizontal
    borderBottomStyle: 'solid', // Establece el estilo del borde inferior
    textAlign: 'center'
  },
});





export default function Formulario_Ilumen() {

  const { getTipoEnsenanza, listTipoEnsenanza, guardarIntitucion, getListarColegios, listColegios, modificarInstitucion, eliminarInstitucion, guardarDirectivo, modificarDirectivo,
    listDirectivos, getListarDirectivos, eliminarDirectivo, guardarProfesorJefe, listProfeJefe, getListarProfeJefe, modificarProfesorJefe, eliminarProfesorJefe,
    guardarSIGE, listSIGE, getListarSIGE, eliminarSIGE, getObtenerUsuario, infoUsuario } = useContext(ComunicacionInternaContext);


  const { imagenUsuario, getObtenerImagenUsuario } = useContext(DatosMiPerfilContext);


  const { estadoIlumen } = useContext(AuthContext)


  useEffect(() => {

    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Dashboard Main" });

    const fetchData = async () => {
      try {
        await getTipoEnsenanza();
        await getListarColegios();
        await getListarDirectivos();
        await getListarProfeJefe();
        await getListarSIGE();
        await getObtenerUsuario();
        await getObtenerImagenUsuario();

        setMostrarTabla(true);
      } catch (error) {
        console.error('Ocurrió un error al ejecutar las funciones:', error);
      }
    };

    fetchData();
  }, []);


  const [primeraLetraNombre, setPrimeraLetraNombre] = useState([]);

  useEffect(() => {
    if (infoUsuario && infoUsuario.nombre) {
      const primeraLetra = infoUsuario.nombre.charAt(0);
      setPrimeraLetraNombre(primeraLetra);
    }
  }, [infoUsuario]);

  const [nombre_institucion, setNombreInstitucion] = useState('');
  const [anio_institucion, setAnioInstitucion] = useState(null);
  const [rbd_institucion, setRbdInstitucion] = useState('');
  const [rbddv_institucion, setRbddvInstitucion] = useState('');
  const [direccion_institucion, setDireccionInstitucion] = useState('');
  const [comuna_institucion, setComunaInstitucion] = useState('');
  const [telefono_institucion, setTelefonoInstitucion] = useState('');
  const [email_institucion, setEmailInstitucion] = useState('');
  const [logo_institucion, setLogoInstitucion] = useState('');
  const [nombre_directivo, setNombreDirectivo] = useState('');
  const [telefono_directivo, setTelefonoDirectivo] = useState('');
  const [correo_directivo, setCorreoDirectivo] = useState('');
  const [cargo_directivo, setCargoDirectivo] = useState('');
  const [colegioDirectivo, setColegioDirectivo] = useState('');
  const [nombre_profesor, setNombreProfesor] = useState('');
  const [telefono_profesor, setTelefonoProfesor] = useState('');
  const [correo_profesor, setCorreoProfesor] = useState('');
  const [cursoProfesorJefe, setCursoProfesorJefe] = useState('');
  const [rbd_sige, setRbdSige] = useState('');
  const [rbddv_sige, setRbddvSige] = useState('');
  const [password_datos_sige, setPasswordDatosSige] = useState('');
  const [id_colegio, setIdColegio] = useState('');
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mostrarExito, setMostrarExito] = useState(false);

  const [idColegioEdit, setIdColegioEdit] = useState(null);
  const [idDirectivoEdit, setIdDirectivoEdit] = useState(null);
  const [idProfesorJefeEdit, setIdProfesorJefeEdit] = useState(null);

  const [directivos, setDirectivos] = useState([]);
  const [instituciones, setInstituciones] = useState([]);
  const [mostrarTabla, setMostrarTabla] = useState(false);
  const [directivoIngresado, setDirectivoIngresado] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');

  const [directivoEditando, setDirectivoEditando] = React.useState(-1); // para dar a conocer que  no se está editando ningún directivo
  const [institucionEditando, setInstitucionEditando] = React.useState(-1);
  const [editandoIndex, setEditandoIndex] = React.useState(-1);


  const [selectedColegio, setSelectedColegio] = useState(null);


  const themeBoxPadding = useTheme();
  const isXsScreenBoxPadding = useMediaQuery(themeBoxPadding.breakpoints.down('xs'));
  const isSmallScreenBoxPadding = useMediaQuery(themeBoxPadding.breakpoints.down('sm'));
  const isMediumScreenBoxPadding = useMediaQuery(themeBoxPadding.breakpoints.down('md'));
  const isLargeScreenBoxPadding = useMediaQuery(themeBoxPadding.breakpoints.down('lg'));
  const isXlScreenBoxPadding = useMediaQuery(themeBoxPadding.breakpoints.down('xl'));

  const padding_pantallas = {
    xs: { paddingLeft: "20px", paddingRight: "20px" },
    sm: { paddingLeft: "30px", paddingRight: "30px" },
    md: { paddingLeft: "60px", paddingRight: "60px" },
    lg: { paddingLeft: "100px", paddingRight: "100px" },
    xl: { paddingLeft: "150px", paddingRight: "150px" },
  };

  const padding = isXsScreenBoxPadding
    ? padding_pantallas.xs
    : isSmallScreenBoxPadding
      ? padding_pantallas.sm
      : isMediumScreenBoxPadding
        ? padding_pantallas.md
        : isLargeScreenBoxPadding
          ? padding_pantallas.lg
          : padding_pantallas.xl;


  const handleChangeNombreInstitucion = (e) => {
    setNombreInstitucion(e.target.value);

  };

  const handleChangeAnioInstitucion = (e) => {
    setAnioInstitucion(e.target.value);

  };

  const handleChangeColegioDirectivo = (e) => {
    setColegioDirectivo(e.target.value);
    console.log(e.target.value)

  };
  /* 
    const handleChangeIdColegio = (e) => {
      setIdColegio(e.target.value);
  
    };
  
  
    const handleItemClick = (item) => {
      setSelectedColegio(item);
  
    };
   */

  const getCurrentYear = () => {
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    return [currentYear.toString(), nextYear.toString()];
  };

  const handleChangeRbdInstitucion = (e) => {
    setRbdInstitucion(e.target.value.replace(/[^0-9]/g, ''));
  };


  const handleChangeRbdSige = (e) => {
    setRbdSige(e.target.value);
  };


  const handleChangeRbddvInstitucion = (e) => {
    setRbddvInstitucion(e.target.value.replace(/[^0-9]/g, ''));

  };

  const handleChangeRbddvSige = (e) => {
    setRbddvSige(e.target.value);
  };

  const handleChangeDireccionInstitucion = (e) => {
    setDireccionInstitucion(e.target.value);
  };

  const handleChangeComunaInstitucion = (e) => {
    setComunaInstitucion(e.target.value);
  };

  const handleChangeTelefonoInstitucion = (e) => {
    setTelefonoInstitucion(e.target.value.replace(/[^0-9]/g, ''));

  };

  const handleChangeEmailInstitucion = (e) => {
    setEmailInstitucion(e.target.value);
  };



  const handleChangeLogoInstitucion = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogoInstitucion(file);
    }
  };

  const handleChangeNombreDirectivo = (e) => {
    setNombreDirectivo(e.target.value);

  };

  const handleChangeTelefonoDirectivo = (e) => {
    setTelefonoDirectivo(e.target.value.replace(/[^0-9]/g, ''));

  };

  const handleChangeCorreoDirectivo = (e) => {
    setCorreoDirectivo(e.target.value);

  };

  const handleChangeCargoDirectivo = (e) => {
    setCargoDirectivo(e.target.value);

  };


  const handleChangeNombreProfesor = (e) => {
    setNombreProfesor(e.target.value);

  };

  const handleChangeTelefonoProfesor = (e) => {
    setTelefonoProfesor(e.target.value.replace(/[^0-9]/g, ''));

  };

  const handleChangeCorreoProfesor = (e) => {
    setCorreoProfesor(e.target.value);

  };

  const handleChangeCursoProfesorJefe = (e) => {
    console.log(e.target.value);
    setCursoProfesorJefe(e.target.value);
  };


  const handleChangePasswordDatosSige = (e) => {
    setPasswordDatosSige(e.target.value);

  };


  const handleGuardarInstitucion = async () => {
    if (
      nombre_institucion.trim() === '' ||
      /*  rbd_institucion.trim() === '' ||*/
      isNaN(parseInt(rbd_institucion.trim())) ||
      isNaN(parseInt(rbddv_institucion.trim())) ||
      direccion_institucion.trim() === '' ||
      comuna_institucion.trim() === '' ||
      telefono_institucion.trim() === '' ||
      email_institucion.trim() === '' ||
      anio_institucion.trim() === '' ||
      isNaN(parseInt(anio_institucion.trim()))

    ) {

      toast.error("Debe ingresar todos los datos de la institución", {
        position: "bottom-left",
        autoClose: 1000,
      });
      return;
    }

    if (!logo_institucion) {
      console.error('No hay archivo seleccionado.');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = async () => {
      const logoBase64 = reader.result;
      await guardarIntitucion(nombre_institucion, anio_institucion, rbd_institucion, rbddv_institucion, direccion_institucion, comuna_institucion, telefono_institucion, email_institucion, logoBase64);
    };

    reader.readAsDataURL(logo_institucion);


    // Limpiar los campos después de agregar 
    setNombreInstitucion('');
    setRbdInstitucion('');
    setRbddvInstitucion('');
    setAnioInstitucion('');
    setDireccionInstitucion('');
    setComunaInstitucion('');
    setTelefonoInstitucion('');
    setEmailInstitucion('');
    setLogoInstitucion('');


    setMostrarTabla(true);

  };


  const [editando, setEditando] = useState(false);

  const handleActualizarFormInstitucion = async (id_colegio) => {
    if (listColegios) {
      var institucion = listColegios.filter(colegio => colegio.id_colegio === id_colegio);
      institucion = institucion[0];
      console.log(listColegios)
      console.log("id_colegio : " + id_colegio)

      console.log(institucion)

      setIdColegioEdit(id_colegio);
      setNombreInstitucion(institucion.nombre);
      setRbdInstitucion(institucion.rbd);
      setRbddvInstitucion(institucion.rbddv);
      setAnioInstitucion(institucion.anio)
      setDireccionInstitucion(institucion.direccion);
      setComunaInstitucion(institucion.comuna);
      setTelefonoInstitucion(institucion.telefono);
      setEmailInstitucion(institucion.email);
      setLogoInstitucion(institucion.logo_institucion);
    }

    console.log(institucion)
    setEditando(true);

  };
  /* 
    const handleActualizarInstitucion = async () => {
      try {
        await modificarInstitucion(
          idColegioEdit,
          nombre_institucion,
          rbd_institucion,
          rbddv_institucion,
          anio_institucion,
          direccion_institucion,
          comuna_institucion,
          telefono_institucion,
          email_institucion,
          logo_institucion
        );
  
  
        setNombreInstitucion('');
        setRbdInstitucion('');
        setRbddvInstitucion('');
        setAnioInstitucion('');
        setDireccionInstitucion('');
        setComunaInstitucion('');
        setTelefonoInstitucion('');
        setEmailInstitucion('');
        setLogoInstitucion(null);
  
      } catch (error) {
        console.error('Error al modificar la institución:', error);
  
      }
  
    } */

  /*  const handleActualizarInstitucion = async () => {
     try {
       const reader = new FileReader();
       reader.onloadend = async () => {
         const logoBase64 = reader.result;
 
         await modificarInstitucion(
           idColegioEdit,
           nombre_institucion,
           rbd_institucion,
           rbddv_institucion,
           anio_institucion,
           direccion_institucion,
           comuna_institucion,
           telefono_institucion,
           email_institucion,
           logoBase64 
         );
 
         setNombreInstitucion('');
         setRbdInstitucion('');
         setRbddvInstitucion('');
         setAnioInstitucion('');
         setDireccionInstitucion('');
         setComunaInstitucion('');
         setTelefonoInstitucion('');
         setEmailInstitucion('');
         setLogoInstitucion(null);
       };
 
       reader.readAsDataURL(logo_institucion);
     } catch (error) {
       console.error('Error al modificar la institución:', error);
     }
   } */

  const handleActualizarInstitucion = async () => {
    try {
      const reader = new FileReader();

      reader.onloadend = async () => {
        const logoBase64 = reader.result;

        await modificarInstitucion(
          idColegioEdit,
          nombre_institucion,
          rbd_institucion,
          rbddv_institucion,
          anio_institucion,
          direccion_institucion,
          comuna_institucion,
          telefono_institucion,
          email_institucion,
          logoBase64
        );

        setNombreInstitucion('');
        setRbdInstitucion('');
        setRbddvInstitucion('');
        setAnioInstitucion('');
        setDireccionInstitucion('');
        setComunaInstitucion('');
        setTelefonoInstitucion('');
        setEmailInstitucion('');
        setLogoInstitucion(null);
      };

      // Verificar si logo_institucion es un Blob
      if (logo_institucion instanceof Blob) {
        reader.readAsDataURL(logo_institucion);
      } else {
        // No se carga una nueva imagen, por lo que el logo permanece igual
        reader.onloadend();
      }
    } catch (error) {
      console.error('Error al modificar la institución:', error);
    }
  }




  const [mensaje, setMensaje] = useState('');

  const handleEliminarInstitucion = async (idColegio) => {
    try {
      await eliminarInstitucion(idColegio);
      setMensaje('Institución eliminada correctamente.');
      await getListarColegios();
    } catch (error) {
      console.error('Error al eliminar la institución:', error);
      setMensaje('Error al eliminar la institución. Consulta la consola para más detalles.');
    }
  };



  const handleGuardarDirectivo = async () => {
    console.log(nombre_directivo, telefono_directivo, correo_directivo, cargo_directivo, colegioDirectivo)

    if (
      nombre_directivo.trim() === '' ||
      telefono_directivo.trim() === '' ||

      correo_directivo.trim() === '' ||
      cargo_directivo.trim() === ''
    ) {

      toast.error("Debe ingresar todos los datos de directivo", {
        position: "bottom-left",
        autoClose: 1000,
      });
      return;
    }


    //setColegioDirectivo(id_colegio);
    await guardarDirectivo(nombre_directivo, telefono_directivo, correo_directivo, cargo_directivo, colegioDirectivo);

    setNombreDirectivo('');
    setTelefonoDirectivo('');
    setCorreoDirectivo('');
    setCargoDirectivo('');
    setColegioDirectivo('');

    await getListarDirectivos();

    setMostrarTabla(true);
  };



  const [editandoDirectivo, setEditandoDirectivo] = useState(false);

  const handleActualizarFormDirectivo = async (id_directivo) => {
    if (listDirectivos) {
      var directivo = listDirectivos.filter(directivo => directivo.id_directivo === id_directivo);
      directivo = directivo[0];
      console.log(listDirectivos)
      console.log("id_directivo : " + id_directivo)

      console.log(directivo)

      setIdDirectivoEdit(id_directivo);
      setNombreDirectivo(directivo.nombre);
      setTelefonoDirectivo(directivo.telefono_celular);
      setCorreoDirectivo(directivo.email);
      setCargoDirectivo(directivo.cargo_directivo);
      setColegioDirectivo(directivo.id_colegio);

    }

    setEditandoDirectivo(true);

  };


  const handleActualizarDirectivo = async () => {
    try {
      await modificarDirectivo(
        idDirectivoEdit,
        nombre_directivo,
        telefono_directivo,
        correo_directivo,
        cargo_directivo,
        colegioDirectivo,

      );


      setNombreDirectivo('');
      setTelefonoDirectivo('');
      setCorreoDirectivo('');
      setColegioDirectivo('');
      setCargoDirectivo('');


    } catch (error) {
      console.error('Error al modificar el directivo:', error);

    }

  }


  const handleEliminarDirectivo = async (idDirectivo) => {
    try {
      await eliminarDirectivo(idDirectivo);
      setMensaje('Directivo eliminado correctamente.');
      await getListarDirectivos();
    } catch (error) {
      console.error('Error al eliminar el Directivo:', error);
      setMensaje('Error al eliminar al directivo. Consulta la consola para más detalles.');
    }
  };



  const handleGuardarProfesorJefe = async () => {
    if (
      nombre_profesor.trim() === ''
    ) {

      toast.error("Debe ingresar todos los datos de Profesor Jefe", {
        position: "bottom-left",
        autoClose: 1000,
      });
      return;
    }

    const id_tipo_ensenanza = cursoProfesorJefe;
    await guardarProfesorJefe(nombre_profesor, telefono_profesor, correo_profesor, id_tipo_ensenanza, letra, colegio);

    await getListarProfeJefe();

    setNombreProfesor('');
    setTelefonoProfesor('');
    setCorreoProfesor('');
    setCursoProfesorJefe('');
    setLetra('');
    setColegio('');


  };


  const handleActualizarProfesorJefe = async () => {
    try {
      await modificarProfesorJefe(
        idProfesorJefeEdit,
        nombre_profesor,
        telefono_profesor,
        correo_profesor,
        cursoProfesorJefe,
        letra,
        colegio
      );


      setNombreProfesor('');
      setTelefonoProfesor('');
      setCorreoProfesor('');
      setCursoProfesorJefe('');
      setLetra('');
      setColegio('');


    } catch (error) {
      console.error('Error al modificar el profesor jefe:', error);

    }

  }


  const [editandoProfesorJefe, setEditandoProfesorJefe] = useState(false);

  const handleActualizarFormProfesorJefe = async (id_profesor_jefe) => {
    if (listProfeJefe) {
      var profesorJefe = listProfeJefe.filter(profesorJefe => profesorJefe.id_profesor_jefe === id_profesor_jefe);
      profesorJefe = profesorJefe[0];
      console.log(profesorJefe)
      console.log("id_profesor_jefe : " + id_profesor_jefe)
      console.log(cursoProfesorJefe)


      setIdProfesorJefeEdit(id_profesor_jefe);
      setNombreProfesor(profesorJefe.nombre);
      setTelefonoProfesor(profesorJefe.telefono_celular);
      setCorreoProfesor(profesorJefe.email);
      setCursoProfesorJefe(profesorJefe.id_tipo_ensenanza);
      setLetra(profesorJefe.letra);
      setColegio(profesorJefe.id_colegio);
    }

    setEditandoProfesorJefe(true);
  };


  const handleEliminarProfesorJefe = async (idProfesorJefe) => {
    try {
      await eliminarProfesorJefe(idProfesorJefe);
      setMensaje('Profesor Jefe eliminado correctamente.');
      await getListarProfeJefe();
    } catch (error) {
      console.error('Error al eliminar el Profesor Jefe:', error);
      setMensaje('Error al eliminar al profesor jefe. Consulta la consola para más detalles.');
    }
  };


  const handleFormDatosSIGE = (event) => {
    const id_colegio = event.target.value;
    setIdColegio(id_colegio);
    if (listColegios) {
      const datosSige = listColegios.filter(colegio => colegio.id_colegio === id_colegio)[0];
      if (datosSige) {
        setColegioSige(id_colegio)
        setRbdSige(datosSige.rbd);
        setRbddvSige(datosSige.rbddv);
      } else {

        setRbdSige('');
        setRbddvSige('');
      }
    }
  };


  const handleGuardarDatosSige = async () => {
    if (password_datos_sige.trim() === '') {
      toast.error("Debe ingresar todos los datos de SIGE", {
        position: "bottom-left",
        autoClose: 1000,
      });
      return;
    }


    setColegioSige(id_colegio);

    try {

      await guardarSIGE(id_colegio, password_datos_sige);

      await getListarSIGE();

      setColegioSige('');
      setRbdSige('');
      setRbddvSige('');
      setPasswordDatosSige('');
    } catch (error) {

      console.error("Error al guardar en SIGE:", error);

      toast.error("Error al guardar en SIGE, por favor inténtelo de nuevo", {
        position: "bottom-left",
        autoClose: 2000,
      });
    }
  };


  const handleEliminarSige = async (idSige) => {
    try {
      console.log(idSige)
      await eliminarSIGE(idSige);
      setMensaje('Usuario Sige eliminada correctamente.');
      await getListarSIGE();
    } catch (error) {
      console.error('Error al eliminar el Usuario Sige :', error);
      setMensaje('Error al eliminar el Usuario Sige. Consulta la consola para más detalles.');
    }
  };


  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isXlScreen = useMediaQuery(theme.breakpoints.down('xl'));


  const [age, setAge] = React.useState('');
  const [letra, setLetra] = useState('');
  const [colegio, setColegio] = useState('');
  const [colegioSige, setColegioSige] = useState('');


  const handleChangeAge = (event) => {
    setAge(event.target.value);
  };

  const handleChangeLetra = (event) => {
    setLetra(event.target.value);
  };

  const handleChangeColegio = (event) => {
    setColegio(event.target.value);
  };


  const fontSize_subtitulo_suscripciones = {
    xs: '10px',
    sm: '10px',
    md: '15px',
    lg: '15px',
    xl: '15px',
  };

  const fontSize_titulos_secciones = {
    xs: '13px',
    sm: '16px',
    md: '16px',
    lg: '16px',
    xl: '16px',
  };

  const fontSize_Inicial_Nombres = {
    xs: '12px',
    sm: '16px',
    md: '16px',
    lg: '16px',
    xl: '16px',
  };

  const fontSize_Nombres = {
    xs: '14px',
    sm: '14px',
    md: '15px',
    lg: '15px',
    xl: '15px',
  };

  const fontSize_Correo_Electronico = {
    xs: '12px',
    sm: '12px',
    md: '14px',
    lg: '14px',
    xl: '14px',
  };



  const handleCloseAlert = () => {
    setMostrarAlerta(false);
  };

  //tamaño del avatar donde se encuentra la inicial del nombre
  const matchesXS = useMediaQuery('(max-width:600px)');
  const matchesSM = useMediaQuery('(min-width:600px)');
  const matchesMD = useMediaQuery('(min-width:960px)');
  const matchesLG = useMediaQuery('(min-width:1280px)');
  const matchesXL = useMediaQuery('(min-width:1920px)');

  let avatarSize = { width: 40, height: 40 };

  if (matchesXS) {
    avatarSize = { width: 30, height: 30 };
  } else if (matchesSM || matchesMD || matchesLG || matchesXL) {
    avatarSize = { width: 40, height: 40 };
  }

  const classes = useStyles();

  const letras_de_cursos = Array.from({ length: 27 }, (_, i) => {
    if (i < 14) {
      return String.fromCharCode(65 + i);
    } else if (i === 14) {
      return 'Ñ';
    } else if (i === 26) {
      return 'Z';
    } else {
      return String.fromCharCode(65 + i - 1);
    }
  });


  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }));


  var rows = []
  if (listColegios) {
    listColegios.map((institucion, index) => {
      rows.push({ id: institucion.id_colegio, nombre: capitalize(institucion.nombre), rbd: institucion.rbd + "-" + institucion.rbddv, direccion: capitalize(institucion.direccion), comuna: capitalize(institucion.comuna), telefono: institucion.telefono, email: institucion.email, logo: institucion.logo })
    })
  }


  const columnsColegio = [
    {
      field: 'nombre',
      headerName: <span style={{ fontWeight: 'bold' }}>Nombre Instituci&oacute;n</span>,
      width: 250,
      editable: false,
    },
    {
      field: 'rbd',
      headerName: <span style={{ fontWeight: 'bold' }}>RBD</span>,
      width: 100,
      editable: false,
    },
    {
      field: 'direccion',
      headerName: <span style={{ fontWeight: 'bold' }}>Direcci&oacute;n</span>,
      width: 250,
      editable: false,
    },
    {
      field: 'comuna',
      headerName: <span style={{ fontWeight: 'bold' }}>Comuna</span>,
      sortable: false,
      width: 160,
    },
    {
      field: 'telefono',
      headerName: <span style={{ fontWeight: 'bold' }}>Tel&eacute;fono</span>,
      sortable: false,
      width: 150,
    },
    {
      field: 'email',
      headerName: <span style={{ fontWeight: 'bold' }}>Correo Electr&oacute;nico</span>,
      sortable: false,
      width: 250,
    },
    {
      field: 'logo',
      headerName: <span style={{ fontWeight: 'bold' }}>Logo</span>,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      width: 160,
      renderCell: (params) => (
        <img src={params.value} alt="Logo" style={{ maxWidth: '90%', maxHeight: '90%' }} />
      ),
    },
    {
      field: 'Opciones',
      headerName: <span style={{ fontWeight: 'bold' }}>Opciones</span>,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      width: 160,
      renderCell: (params) => (
        <div style={{ display: 'flex' }}>
          <IconButton onClick={() => handleEliminarInstitucion(params.row.id)}>
            <DeleteIcon style={{ color: '#f1948d' }} />
          </IconButton>
          <IconButton onClick={() => handleActualizarFormInstitucion(params.row.id)}>
            <EditIcon style={{ color: '#6e8be38a' }} />
          </IconButton>
        </div >
      ),
    },
  ];




  var rowsDirectivo = []
  if (listDirectivos) {
    listDirectivos.map((directivo, index) => {
      rowsDirectivo.push({ id: directivo.id_directivo, nombre: capitalize(directivo.nombre), telefono_celular: directivo.telefono_celular, email: directivo.email, cargo: capitalize(directivo.cargo_directivo), id_colegio: capitalize(directivo.nombre_colegio) })
    })
  }

  const columnsDirectivo = [
    {
      field: 'nombre',
      headerName: <span style={{ fontWeight: 'bold' }}>Nombre</span>,
      width: 250,
      editable: false,
    },
    {
      field: 'telefono_celular',
      headerName: <span style={{ fontWeight: 'bold' }}>Tel&eacute;fono</span>,
      width: 150,
      editable: false,
    },
    {
      field: 'email',
      headerName: <span style={{ fontWeight: 'bold' }}> Correo Electr&oacute;nico</span>,
      width: 250,
      editable: false,
    },
    {
      field: 'cargo',
      headerName: <span style={{ fontWeight: 'bold' }}>Cargo</span>,
      sortable: false,
      width: 250,
    },
    {
      field: 'id_colegio',
      headerName: <span style={{ fontWeight: 'bold' }}> Colegio</span>,
      sortable: false,
      width: 250,
    },
    {
      field: 'Opciones',
      headerName: <span style={{ fontWeight: 'bold' }}>Opciones</span>,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      width: 160,
      renderCell: (params) => (
        <div style={{ display: 'flex' }}>
          <IconButton onClick={() => handleEliminarDirectivo(params.row.id)}>
            <DeleteIcon style={{ color: '#f1948d' }} />
          </IconButton>
          <IconButton onClick={() => handleActualizarFormDirectivo(params.row.id)}>
            <EditIcon style={{ color: '#6e8be38a' }} />
          </IconButton>
        </div >
      ),
    },
  ];



  var rowsProfeJefe = []
  if (listProfeJefe) {
    listProfeJefe.map((profesorJefe, index) => {
      rowsProfeJefe.push({ id: profesorJefe.id_profesor_jefe, nombre: capitalize(profesorJefe.nombre), telefono_celular: profesorJefe.telefono_celular, email: profesorJefe.email, grado: capitalize(profesorJefe.grado), letra: profesorJefe.letra, id_colegio: capitalize(profesorJefe.nombre_colegio) })
    })
  }

  const columnsProfeJefe = [
    {
      field: 'nombre',
      headerName: <span style={{ fontWeight: 'bold' }}>Nombre</span>,
      width: 250,
      editable: false,
    },
    {
      field: 'telefono_celular',
      headerName: <span style={{ fontWeight: 'bold' }}>Tel&eacute;fono</span>,
      width: 150,
      editable: false,
    },
    {
      field: 'email',
      headerName: <span style={{ fontWeight: 'bold' }}>Correo Electr&oacute;nico</span>,
      width: 250,
      editable: false,
    },
    {
      field: 'grado',
      headerName: <span style={{ fontWeight: 'bold' }}>Grado</span>,
      width: 250,
      editable: false,
    },
    {
      field: 'letra',
      headerName: <span style={{ fontWeight: 'bold' }}>Letra</span>,
      sortable: false,
      width: 40,
    },
    {
      field: 'id_colegio',
      headerName: <span style={{ fontWeight: 'bold' }}> Colegio</span>,
      sortable: false,
      width: 250,
    },
    {
      field: 'Opciones',
      headerName: <span style={{ fontWeight: 'bold' }}>Opciones</span>,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      width: 160,
      renderCell: (params) => (
        <div style={{ display: 'flex' }}>
          <IconButton onClick={() => handleEliminarProfesorJefe(params.row.id)}>
            <DeleteIcon style={{ color: '#f1948d' }} />
          </IconButton>
          <IconButton onClick={() => handleActualizarFormProfesorJefe(params.row.id)}>
            <EditIcon style={{ color: '#6e8be38a' }} />
          </IconButton>
        </div >
      ),
    },
  ];




  var rowsSige = []
  if (listSIGE) {
    listSIGE.map((datosSige, index) => {
      rowsSige.push({ id: datosSige.id_sige, id_colegio: capitalize(datosSige.id_colegio), clave: datosSige.clave })
    })
  }

  const columnsPerfilSige = [
    {
      field: 'id_colegio',
      headerName: <span style={{ fontWeight: 'bold' }}>Colegio</span>,
      width: 250,
      editable: false,
    },
    {
      field: 'clave',
      headerName: <span style={{ fontWeight: 'bold' }}>Clave</span>,
      width: 450,
      editable: false,
    },
    {
      field: 'Opción',
      headerName: <span style={{ fontWeight: 'bold' }}>Opci&oacute;n</span>,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      width: 160,
      renderCell: (params) => (
        <div style={{ display: 'flex' }}>
          <IconButton onClick={() => handleEliminarSige(params.row.id)}>
            <DeleteIcon style={{ color: '#f1948d' }} />
          </IconButton>
        </div >
      ),
    },
  ];
  return (
    <ThemeProvider theme={theme_formulario_ilumen}>
      <Container maxWidth="lg">
        <Box >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginBottom: '10px', paddingLeft: '30px', alignContent: 'center', textAlign: 'center' }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'center' }, marginBottom: { xs: '15px', md: '0' } }}>
                <Box display="flex" alignItems="center" >
                  <Avatar
                    sx={{
                      width: 40, height: 40,
                      marginRight: { xs: 1, md: 2 },
                    }}
                  >
                    {imagenUsuario === null ? (
                      <PersonIcon style={{ width: '70%', height: '70%' }} />
                    ) : (
                      <img src={imagenUsuario} style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
                    )}
                  </Avatar>
                  <div>
                    <Typography variant="h6" sx={{
                      fontSize: fontSize_Nombres,
                      textAlign: 'left',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}>
                      {infoUsuario.nombre}
                    </Typography>

                    <Box display="flex" alignItems="center" >
                      <EmailIcon style={{ fontSize: 16, marginRight: 1, color: '#706F6F' }} />
                      <Typography variant="body2" sx={{
                        fontSize: fontSize_Correo_Electronico,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}>
                        {infoUsuario.email}
                      </Typography>
                    </Box>
                  </div>
                </Box>
              </Grid>


              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'center' }, textAlign: 'center' }}>
                <Box display="flex" alignItems="center" flexDirection={{ xs: 'row', md: 'row' }} >
                  <Avatar style={{ backgroundColor: '#91dae5', width: 40, height: 40, marginRight: '10px' }}>
                    <KeyIcon style={{ width: '25', height: '25' }} />
                  </Avatar>
                  <Box>
                    <Link to="/cambiar_password" variant="body1" style={{  /* fontSize: fontSize_Nombres, marginBottom: '5px', color: 'black', */ textDecoration: 'none'/* , textAlign: 'left' */ }}>
                      <Typography variant="h6" sx={{
                        fontSize: fontSize_Nombres
                      }}>
                        Cambiar Contraseña
                      </Typography>
                    </Link>
                    <Typography variant="body2" style={{ fontSize: '12px', textAlign: 'left' }}>Seguridad</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>


          {/* despliegue Suscripciones  */}
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Accordion sx={{ width: '100%', marginBottom: '5px' }} defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                  <Grid item xs={6} md={6} sx={{ display: 'flex', justifyContent: 'left', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                      <CardMembershipIcon sx={{ marginRight: '0.5rem', color: '#008CFF' }} />
                      Suscripciones
                    </Typography>
                    <Typography variant="body2" sx={{ marginLeft: 1, marginTop: 1, fontSize: fontSize_subtitulo_suscripciones, textAlign: 'justify' }}>Vea y administre sus productos y suscripciones de Khronolatam</Typography>
                  </Grid>
                  <Grid item xs={6} md={6} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: fontSize_titulos_secciones, textAlign: "end" }}>Ver todas las suscripciones</Typography>
                  </Grid>
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                  <Box >
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                      <Grid item xs={3} sm={3} md={3} lg={3} xl={3} sx={{ display: 'flex', justifyContent: 'left', flexDirection: 'column', alignItems: 'flex-start' }} >
                        <img
                          src={img_ilumen}
                          alt="Descripción de la imagen"
                          /*   style={{
                              width: '30%',
                              height: 'auto',
   
                            }} */
                          style={{
                            width: isXsScreen ? '60%' : (isSmallScreen ? '50%' : (isLargeScreen ? '30%' : (isXlScreen ? '30%' : '30%'))),
                            height: 'auto',
                            display: 'block',
                            margin: '0 auto',
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'left', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'left' }}>iLUMEN EduTech</Typography>
                        <Typography variant="body2" sx={{ textAlign: 'justify' }}>Compartido y administrado por ...</Typography>
                        <a href={`https://${url}`} target="_blank" style={{ textDecoration: 'none', color: '#008CFF' }}>
                          Ver Ventajas
                        </a>
                        {estadoIlumen && (
                          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                            Ilumen habilitado.
                          </Alert>
                        )}
                        {!estadoIlumen && (
                          <>
                            <Alert icon={<CloseIcon fontSize="inherit" />} severity="error">
                              Ilumen deshabilitado.
                            </Alert>
                          </>
                        )}

                      </Grid>
                    </Grid>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          {/* despliegue datos de institucion */}
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Accordion sx={{ width: '100%', marginBottom: '5px' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Grid item xs={6} md={6} sx={{ display: 'flex', justifyContent: 'left', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', textAlign: 'justify' }}>
                      <ApartmentIcon sx={{ marginRight: '0.5rem', color: '#008CFF' }} />
                      Datos de la Institución
                    </Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ padding }}>
                    <Grid container spacing={2} >
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Typography variant="h6" sx={{ textAlign: 'left' }}>
                          RBD:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8} sx={{ alignItems: 'flex-start', display: 'flex' }}>
                        <TextField
                          sx={{ width: '180px' }}
                          size="small"
                          value={rbd_institucion}
                          onChange={handleChangeRbdInstitucion}
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <BadgeIcon />
                              </InputAdornment>
                            ),


                          }}
                        />
                        &nbsp;
                        <Typography variant="h6" >
                          &nbsp; - &nbsp;
                        </Typography>
                        &nbsp;
                        <TextField
                          sx={{ width: '80px' }}
                          size="small"
                          value={rbddv_institucion}
                          onChange={handleChangeRbddvInstitucion}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Typography variant="h6" sx={{ textAlign: 'left' }} >
                          Nombre:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <TextField
                          fullWidth
                          size="small"
                          value={nombre_institucion}
                          onChange={handleChangeNombreInstitucion}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <ApartmentIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                        />
                      </Grid>


                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Typography variant="h6" sx={{ textAlign: 'left' }} >
                          Año:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
                          {/* <InputLabel id="demo-select-small-label">Año</InputLabel> */}
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={anio_institucion}
                            label="Año"
                            onChange={handleChangeAnioInstitucion}
                            input={<BootstrapInput />} //le agregue esto para que no se viera con el espacio en blanco del inputlabel comentado
                          >
                            <MenuItem value="">
                              <em>Seleccione el año</em>
                            </MenuItem>
                            {getCurrentYear().map((year) => (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Typography variant="h6" sx={{ textAlign: 'left' }}>
                          Direcci&oacute;n:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <TextField
                          fullWidth
                          size="small"
                          value={direccion_institucion}
                          onChange={handleChangeDireccionInstitucion}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LocationOnIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Typography variant="h6" sx={{ textAlign: 'left' }}>
                          Comuna
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <TextField
                          fullWidth
                          size="small"
                          value={comuna_institucion}
                          onChange={handleChangeComunaInstitucion}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LocationOnIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Typography variant="h6" sx={{ textAlign: 'left' }}>
                          Tel&eacute;fono:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <TextField
                          fullWidth
                          size="small"
                          value={telefono_institucion}
                          onChange={handleChangeTelefonoInstitucion}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LocalPhoneIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Typography variant="h6" sx={{ textAlign: 'left' }}>
                          Email contacto:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <TextField
                          fullWidth
                          size="small"
                          type="email"
                          value={email_institucion}
                          onChange={handleChangeEmailInstitucion}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <MailIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Typography variant="h6" sx={{ textAlign: 'left' }}>
                          Logo:
                        </Typography>

                        <input
                          accept="image/*"
                          id="logo-input"
                          type="file"
                          style={{ display: 'none' }}
                          onChange={handleChangeLogoInstitucion}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <label htmlFor="logo-input">
                          <TextField
                            disabled
                            fullWidth
                            size="small"
                            value={logo_institucion ? logo_institucion.name : ''}
                            helperText="Seleccione una imagen"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AttachmentIcon />
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            onClick={(e) => { e.target.value = null }}
                          />
                        </label>
                      </Grid>

                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: "right", marginBottom: '20px' }}>
                      <Box sx={{
                        '& > *': {
                          m: 1,
                          textAlign: 'center',
                          display: 'flex',
                          flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' },
                          justifyContent: 'flex-end',
                        },
                        '& > *:not(:last-child)': {
                          mb: { xs: 1, sm: 0, md: 0, lg: 0, xl: 0 },
                          mr: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 }
                        }
                      }}>
                        <Button variant="contained" sx={{ width: { xs: '100%', sm: 'auto', md: 'auto', lg: 'auto', xl: 'auto' } }} onClick={handleGuardarInstitucion}>Agregar Instituci&oacute;n</Button>
                        <Button variant="contained" sx={{ width: { xs: '100%', sm: 'auto', md: 'auto', lg: 'auto', xl: 'auto' } }} onClick={handleActualizarInstitucion}>Actualizar Instituci&oacute;n</Button>
                      </Box>
                    </Grid>


                    <DataGrid
                      autoHeight
                      autoWidth
                      rows={rows}
                      columns={columnsColegio}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5]}
                      rowHeight={30}
                      columnHeaderHeight={40}
                      disableRowSelectionOnClick
                    />
                    <Divider />



                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          {/* despliegue de datos de Equipo para la comunicación Interna */}
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Accordion sx={{ width: '100%', marginBottom: '5px' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                  <Grid item xs={6} md={6} sx={{ display: 'flex', justifyContent: 'left', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', textAlign: 'justify' }}>
                      <GroupsIcon sx={{ marginRight: '0.5rem', color: '#008CFF' }} />
                      Equipo para la Comunicaci&oacute;n Interna
                    </Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ padding }}>
                    <Grid container spacing={1} sx={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center', marginBottom: '40px' }}>
                      {/* Primera fila */}
                      <Grid item xs={4} sx={{ gridRow: '1', gridColumn: '1 / span 3' }}>
                        <Typography variant="h6" sx={{ textAlign: "left" }}>
                          Directivo
                        </Typography>
                      </Grid>
                      {/* Segunda fila */}
                      <Grid item xs={4}>
                        <TextField
                          value={nombre_directivo}
                          onChange={handleChangeNombreDirectivo}
                          fullWidth
                          id="outlined-basic"
                          label="Nombre"
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          value={telefono_directivo}
                          onChange={handleChangeTelefonoDirectivo}
                          fullWidth
                          id="outlined-basic"
                          label="Teléfono"
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        &nbsp;
                      </Grid>
                      {/* Tercera fila */}
                      <Grid item xs={8} sx={{ gridRow: '3', gridColumn: '1 / span 2' }}>
                        <TextField
                          value={correo_directivo}
                          onChange={handleChangeCorreoDirectivo}
                          fullWidth
                          id="outlined-basic"
                          label="Correo Electrónico"
                          variant="outlined"
                          size="small"
                        />
                      </Grid>

                      <Grid item xs={4}>
                        &nbsp;
                      </Grid>

                      <Grid item xs={8} sx={{ gridRow: '3', gridColumn: '1 / span 2' }}>
                        <TextField
                          value={cargo_directivo}
                          onChange={handleChangeCargoDirectivo}
                          fullWidth
                          id="outlined-basic"
                          label="Cargo"
                          variant="outlined"
                          size="small"
                        />
                      </Grid>

                      <Grid item xs={4}>
                        &nbsp;
                      </Grid>

                      <Grid item xs={8} sx={{ gridRow: '3', gridColumn: '1 / span 2' }}>
                        <FormControl size="small" fullWidth sx={{ textAlign: 'left' }} >
                          <InputLabel id="demo-select-small-label">Colegio</InputLabel>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={colegioDirectivo}
                            label="Colegio"
                            onChange={handleChangeColegioDirectivo}
                          >
                            <MenuItem value="">
                              <em>Seleccione un colegio</em>
                            </MenuItem>
                            {listColegios && (
                              listColegios.map(item => (
                                <MenuItem
                                  key={item.id_colegio}
                                  value={item.id_colegio}
                                  selected={colegioDirectivo === item.id_colegio}

                                >{capitalize(item.nombre)} / {item.anio}</MenuItem>
                              ))
                            )}
                          </Select>
                        </FormControl>
                      </Grid>



                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: "right", marginBottom: '20px' }}>
                        <Box sx={{
                          '& > *': {
                            m: 1,
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' },
                            justifyContent: 'flex-end',
                          },
                          '& > *:not(:last-child)': {
                            mb: { xs: 1, sm: 0, md: 0, lg: 0, xl: 0 },
                            mr: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 }
                          }
                        }}>
                          <Button variant="contained" sx={{ width: { xs: '100%', sm: 'auto', md: 'auto', lg: 'auto', xl: 'auto' } }} onClick={handleGuardarDirectivo}>Agregar Directivo</Button>
                          <Button variant="contained" sx={{ width: { xs: '100%', sm: 'auto', md: 'auto', lg: 'auto', xl: 'auto' } }} onClick={handleActualizarDirectivo}>Actualizar Directivo</Button>
                        </Box>
                      </Grid>

                      <DataGrid
                        autoHeight
                        autoWidth
                        rows={rowsDirectivo}
                        columns={columnsDirectivo}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 5,
                            },
                          },
                        }}
                        pageSizeOptions={[5]}
                        rowHeight={30}
                        columnHeaderHeight={40}
                        disableRowSelectionOnClick
                      />



                    </Grid>


                    <Grid container spacing={1} sx={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center' }}>
                      {/* Primera fila */}
                      <Grid item xs={4} sx={{ gridRow: '1', gridColumn: '1 / span 3' }}>
                        <Typography variant="h6" sx={{ textAlign: 'left' }}>
                          Profesor Jefe
                        </Typography>
                      </Grid>
                      {/* Segunda fila */}
                      <Grid item xs={4}>
                        <TextField
                          value={nombre_profesor}
                          onChange={handleChangeNombreProfesor}
                          fullWidth
                          id="outlined-basic"
                          label="Nombre"
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          value={telefono_profesor}
                          onChange={handleChangeTelefonoProfesor}
                          fullWidth
                          id="outlined-basic"
                          label="Teléfono"
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        &nbsp;
                      </Grid>
                      {/* Tercera fila */}
                      <Grid item xs={8} sx={{ gridRow: '3', gridColumn: '1 / span 2' }}>
                        <TextField
                          value={correo_profesor}
                          onChange={handleChangeCorreoProfesor}
                          fullWidth
                          id="outlined-basic"
                          label="Correo Electrónico"
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        &nbsp;
                      </Grid>
                      <Grid item xs={4} sx={{ gridRow: '3', gridColumn: '1 / span 2' }}>
                        <FormControl size="small" fullWidth >
                          <InputLabel id="demo-select-small-label">Curso</InputLabel>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={cursoProfesorJefe}
                            label="Curso"
                            onChange={handleChangeCursoProfesorJefe}
                          >
                            <MenuItem value="">
                              <em>Seleccione un curso</em>
                            </MenuItem>
                            {listTipoEnsenanza && (
                              listTipoEnsenanza.map(item => (
                                <MenuItem sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', }} key={item.id_tipo_ensenanza} value={item.id_tipo_ensenanza} selected={cursoProfesorJefe === item.id_tipo_ensenanza}>{item.descripcion + " (" + item.glosa + ")"}</MenuItem>
                              ))
                            )}

                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={4} sx={{ gridRow: '3', gridColumn: '1 / span 2' }}>
                        <FormControl size="small" fullWidth >
                          <InputLabel id="demo-select-small-label">Letra</InputLabel>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={letra}
                            label="Letra"
                            onChange={handleChangeLetra}
                          >
                            <MenuItem value="">
                              <em>Seleccione una letra</em>
                            </MenuItem>

                            {letras_de_cursos.map((letter, index) => (
                              <MenuItem key={index} value={letter}>{letter}</MenuItem>
                            ))}

                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={4}>
                        &nbsp;
                      </Grid>

                      <Grid item xs={8} sx={{ gridRow: '3', gridColumn: '1 / span 2' }}>
                        <FormControl size="small" fullWidth sx={{ textAlign: 'left' }}>
                          <InputLabel id="demo-select-small-label">Colegio</InputLabel>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={colegio}
                            label="Colegio"
                            onChange={handleChangeColegio}
                          >
                            <MenuItem value="">
                              <em>Seleccione un colegio</em>
                            </MenuItem>
                            {listColegios && (
                              listColegios.map(item => (
                                <MenuItem key={item.id_colegio} value={item.id_colegio} selected={colegio === item.id_colegio}>{capitalize(item.nombre)} / {item.anio}</MenuItem>
                              ))
                            )}

                          </Select>
                        </FormControl>
                      </Grid>


                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: "right", marginBottom: '20px' }}>
                        <Box sx={{
                          '& > *': {
                            m: 1,
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' },
                            justifyContent: 'flex-end',
                          },
                          '& > *:not(:last-child)': {
                            mb: { xs: 1, sm: 0, md: 0, lg: 0, xl: 0 },
                            mr: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 }
                          }
                        }}>
                          <Button variant="contained" sx={{ width: { xs: '100%', sm: 'auto', md: 'auto', lg: 'auto', xl: 'auto' } }} onClick={handleGuardarProfesorJefe} >Agregar Profesor Jefe</Button>
                          <Button variant="contained" sx={{ width: { xs: '100%', sm: 'auto', md: 'auto', lg: 'auto', xl: 'auto' } }} onClick={handleActualizarProfesorJefe}>Actualizar Profesor Jefe</Button>
                        </Box>
                      </Grid>

                      <DataGrid
                        autoHeight
                        autoWidth
                        rows={rowsProfeJefe}
                        columns={columnsProfeJefe}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 5,
                            },
                          },
                        }}
                        pageSizeOptions={[5]}
                        rowHeight={30}
                        columnHeaderHeight={40}
                        disableRowSelectionOnClick
                      />

                    </Grid>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>


          {/* Despliegue Datos SIGE */}
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Accordion sx={{ width: '100%', marginBottom: '5px' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Grid item xs={6} md={6} sx={{ display: 'flex', justifyContent: 'left', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                      <ShieldIcon sx={{ marginRight: '0.5rem', color: '#008CFF' }} />
                      Datos SIGE
                    </Typography>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ padding }}>
                    <Grid container spacing={2} >
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Typography variant="h6" sx={{ textAlign: 'left' }} >
                          Seleccione un colegio:
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <FormControl size="small" fullWidth >
                          <InputLabel id="demo-select-small-label">Colegio</InputLabel>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={colegioSige}
                            label="Colegio"
                            onChange={handleFormDatosSIGE}
                          >
                            <MenuItem value="">
                              <em>Seleccione un colegio</em>
                            </MenuItem>
                            {listColegios && (
                              listColegios.map(item => (
                                <MenuItem key={item.id_colegio} value={item.id_colegio} selected={colegioSige === item.id_colegio}>{capitalize(item.nombre)} / {item.anio}</MenuItem>
                              ))
                            )}
                          </Select>
                        </FormControl>
                      </Grid>


                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Typography variant="h6" sx={{ textAlign: 'left' }}>
                          Nombre RBD:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8} sx={{ alignItems: 'flex-start', display: 'flex' }}>
                        <TextField
                          sx={{ width: '180px' }}
                          size="small"
                          value={rbd_sige}
                          onChange={handleChangeRbdSige}
                          variant="standard"
                          disabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <BadgeIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        &nbsp;
                        <Typography variant="h6" >
                          -
                        </Typography>
                        &nbsp;
                        <TextField
                          sx={{ width: '80px' }}
                          size="small"
                          value={rbddv_sige}
                          disabled
                          onChange={handleChangeRbddvSige}
                          variant="standard"
                        />

                      </Grid>

                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Typography variant="h6" sx={{ textAlign: 'left' }} >
                          Contraseña RBD:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        <TextField
                          fullWidth
                          size="small"
                          type='password'
                          value={password_datos_sige}
                          onChange={handleChangePasswordDatosSige}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PasswordIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="standard"
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: "right", marginBottom: '20px' }}>
                      <Box sx={{
                        '& > *': {
                          m: 1,
                          textAlign: 'center',
                          display: 'flex',
                          flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' },
                          justifyContent: 'flex-end',
                        },
                        '& > *:not(:last-child)': {
                          mb: { xs: 1, sm: 0, md: 0, lg: 0, xl: 0 },
                          mr: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1 }
                        }
                      }}>
                        <Button variant="contained" sx={{ width: { xs: '100%', sm: 'auto', md: 'auto', lg: 'auto', xl: 'auto' } }} onClick={handleGuardarDatosSige}>Guardar Credenciales de SIGE</Button>
                      </Box>
                    </Grid>

                    <DataGrid
                      autoHeight
                      autoWidth
                      rows={rowsSige}
                      columns={columnsPerfilSige}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5]}
                      rowHeight={30}
                      columnHeaderHeight={40}
                      disableRowSelectionOnClick
                    />

                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          {mostrarAlerta && (
            <Alert variant="filled" severity="error" onClose={handleCloseAlert}>
              Por favor, complete todos los datos.
            </Alert>
          )}
          {mostrarExito && (
            <Alert variant="filled" severity="success" onClose={() => setMostrarExito(false)}>
              Los datos han sido guardados exitosamente.
            </Alert>
          )}

        </Box>
      </Container>
    </ThemeProvider >

  )
}
